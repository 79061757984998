import Keycloak from "keycloak-js";

const keycloakUrl = "REACT_APP_KEYCLOAK_URL_PLACEHOLDER";
// const keycloakUrl = "http://localhost:8081/auth";
const keycloak = new Keycloak({
    url: keycloakUrl,
    realm: 'hia-realm', 
    clientId: 'front-hia',
    onLoad: 'login-required',
});

export default keycloak;


/*
axios.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${keycloak.token}`;
  return config;
});

*/
