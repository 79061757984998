import axios from 'axios';
import { loadAckToken, saveAckToken } from '../utils/sessionStorage';

export const SET_CONVENTION_BY_HASH = 'SET_CONVENTION_BY_HASH';
export const SET_ACKNOWLEDGE_STATUS = 'SET_ACKNOWLEDGE_STATUS';
export const SET_URL_ERROR = 'SET_URL_ERROR';

export const setConventionByHash = ({ data }) => ({ type: SET_CONVENTION_BY_HASH, payload: data })
export const setAcknowledgeStatus = (status) => ({ type: SET_ACKNOWLEDGE_STATUS, status });
export const setUrlError = (error) => ({ type: SET_URL_ERROR, error })


export const loadConventionByHash = (id, language) => async (dispatch) => {
    console.log("loadConventionByHash id:", id);
    console.log("loadConventionByHash language:", language);

    try {
        // Step 1: Get the token from the Token API
        const tokenResponse = await axios.post('/get_token');

        // const tokenResponse = await axios.post(
        //     `http://localhost:8081/auth/realms/hia-realm/protocol/openid-connect/token`, 
        //     `grant_type=client_credentials&client_id=customer-service&client_secret=KdF5Y6TOtJVzuiWUqjaTVJvkBZrnobCL`, 
        //     {
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded',
        //         },
        //     }
        // );
        saveAckToken(tokenResponse.data);
        const token = tokenResponse.data.access_token;

        // Step 2: Call the loadConventionByHash API with the token in headers
        if(token){
            const response = await axios.post(
                `/back-hia/api/v1/conventions/${language}/ack-start/?ackToken=${id}`, null,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
    
            if (response.status === 200) {
                dispatch(setConventionByHash(response));
            }
        }
    } catch (error) {
        console.error("Error in loadConventionByHash:", error);
        dispatch(setUrlError(error.response.data.errors[0].key || 'Unknown error'));
    }
}

export const acknowledgeOfReceipt = (id) => (dispatch) => {
    console.log("acknowledgeOfReceipt id:", id);
    const ackToken = loadAckToken();

    axios.put(`/back-hia/api/v1/conventions/ack?ackToken=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${ackToken.access_token}`
            }
        }).then(() => {
                dispatch(setAcknowledgeStatus(true));
            
        }).catch(function (error) {
            // dispatch(setLoginError(error.response.data));
        })
}


